<template>
    <BaseModalTemplate
        title="Resultado comprobación de enlaces"
        :open-close-dialog="displayDialog"
        card_extra_classes="w-100 overflow-x-auto"
        :loading="loading"
        :hide_actions="true"
    >
        <template #body-modal-content>
            <v-row v-if="!variableIsDefined(seo_description)">
                <v-col>
                    <span>El contenido de descripción SEO está vacío, no hay enlaces que comprobar.</span>
                </v-col>
            </v-row>
            <v-row v-else-if="links_to_check.length === 0" >
                <v-col>
                    <span>No se ha encontrado ninguna URL en la descripción SEO.</span>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12">
                            <span class="input-title">
                                    URL correctas:</span>
                </v-col>
                <v-col cols="12">
                    <table v-if="ok_length > 0" class="tabla-datos">
                        <thead>
                            <tr class="table-header">
                                <th class="table-content">URL</th>
                                <th class="table-content">Código de estado</th>
                            </tr>
                        </thead>
                        <tr v-for="ok in urls['ok']">
                            <td class="table-content">{{ ok.url }}</td>
                            <td class="table-content">{{ ok.status_code }}</td>
                        </tr>
                    </table>
                    <span v-else>
                            No hay ningún enlace correcto.
                        </span>
                </v-col>
                <v-col cols="12">
                            <span class="input-title">
                                    URL redirigidas:</span>
                </v-col>
                <v-col cols="12">
                    <table v-if="redirect_length > 0" class="tabla-datos">
                        <thead>
                            <tr class="table-header">
                                <th class="table-content">URL de origen</th>
                                <th class="table-content">Tipo de redirección</th>
                                <th class="table-content">Redirecciones</th>
                            </tr>
                        </thead>
                        <tr v-for="red in urls['redirect']">
                            <td class="table-content">{{ red.url }}</td>
                            <td class="table-content"> {{ red.status_code }}</td>
                            <td class="table-content">
                                <li class="redirection-item">
                                    <u>URL</u> <br> {{ red.redirect_url[0].url }} <br> <u>Código de estado</u> <br>{{red.redirect_url[0].status_code}}
                                    <br>
                                </li>
                            </td>
                        </tr>
                    </table>

                    <span v-else>
                            No hay ningún enlace redirigido.
                        </span>
                </v-col>
                <v-col cols="12">
                            <span class="input-title">
                                    URL no encontradas:</span>
                </v-col>
                <v-col cols="12">
                    <table v-if="ko_length > 0" class="tabla-datos ">
                        <thead>
                            <tr class="table-header">
                                <th class="table-content">URL de origen</th>
                                <th class="table-content">Código de estado</th>
                            </tr>
                        </thead>
                        <tr v-for="not in urls['ko']">
                            <td class="table-content">{{ not.url }}</td>
                            <td class="table-content">{{ not.status_code }}</td>
                        </tr>
                    </table>
                    <span v-else>
                            No hay ningún enlace no encontrado.
                        </span>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import Loading from "@/components/Generic/Loading.vue";
import {checkBrokenURLWS, checkURLWS} from "@/utils/editor_course_blog";
import {getHtmlElements, showNotification, variableIsDefined} from "@/utils/aux_functions";
import Vue3EasyDataTable from "vue3-easy-data-table";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "ModalURLCheck",
    methods: {variableIsDefined},
    components: {BaseModalTemplate, Vue3EasyDataTable, Loading},
    props: {
        displayDialog: Function,
        seo_description: String,
    },
    setup(props) {
        let loading = ref(false);
        let urls = ref({})

        let redirect_length = ref()
        let ko_length = ref()
        let ok_length = ref()

        const links_to_check = ref()

        onBeforeMount(async () => {
            if (variableIsDefined(props.seo_description)) {
                loading.value = true;
                await checkURLs();
                loading.value = false;
            }
        });


        let header = ref([
            {text: 'URL de origen', value: 'url_origin'},
            {text: 'Tipo de redirección', value: 'type'},
        ])

        let header_not_found = ref([
            {text: 'URL de origen', value: 'url_origin'},
        ])

        /*
        *
        * ENVIA AL WS EL CONTENIDO DEL CAMPO DESCRIPCIÓN SEO
        * QUE RECIBE POR PROP
        * RECIBE UN OBJETO CON DOS ARRAY
        *   redirected: CONTIENE LAS URL ENCONTRADAS QUE HAN
        *               SIDO REDIRECCIONADAS Y SU TIPO DE REDIRECCIÓN
        *   not_found: CONTIENE LAS URL QUE NO HAN SIDO ENCONTRADAS
        *
        * */
        async function checkURLs() {
            links_to_check.value = validateDescription()
            if (links_to_check.value.length > 0){
                let response = await checkBrokenURLWS(links_to_check.value);

                if (response['result']) {
                    urls.value.redirect = response['data']['urls_redirect'];
                    urls.value.ko = response['data']['urls_ko'];
                    urls.value.ok = response['data']['urls_ok'];

                    redirect_length.value = urls.value.redirect.length
                    ko_length.value = urls.value.ko.length
                    ok_length.value = urls.value.ok.length
                } else {
                    showNotification('error', response['errors'])
                }
            }
        }

        const validateDescription = () => {
            const html_links = getHtmlElements(props.seo_description, 'a')
            const links_to_send = [];

            const array = [...html_links].map((link) => {
                links_to_send.push(link.href)
            })

            return links_to_send
        }

        return {
            header,
            header_not_found,
            ko_length,
            links_to_check,
            loading,
            ok_length,
            redirect_length,
            urls,

        }
    }
}
</script>

<style lang="scss">

.redirection-item {
    list-style: none;
    padding-bottom: 2vh;
}

.tabla-datos {
    thead, tbody, tfoot, tr, td, th {
        border-width: 1px 1px !important;
    }

    overflow-x: scroll;
    width: 100%;
    border-color: #DADCE0 !important;

    .table-header{
        height: 5vh;
        width: auto !important;
    }

    .table-content{
        padding: 5px 0 5px 15px;
    }
}

</style>