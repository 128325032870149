<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" sm="9" lg="10">
                <span class="main-title-page">
                    <img :src="require('@/assets/icons/navbar/link.svg')" alt="icono de lista urls" width="28"
                         height="28">
                    Lista AAFF destacadas desactivadas
                </span>
            </v-col>
        </v-row>
        <template v-if="general_loading">
            <Loading/>
        </template>
        <template v-else>
            <template v-if="!pending_language">
                <v-row justify="end" class="mt-2">
                    <v-col cols="12" class="text-end align-self-center">
                        <div class="d-flex">
                            <v-text-field
                                class="text-field-secondary"
                                v-model.trim="url_search_data.url"
                                @keyup.enter="getURLsByPortal"
                                placeholder="Url a buscar"
                                single-line
                                bg-color="white"
                                color="transparent"
                                hide-details
                            >
                                <template #append-inner>
                                    <v-btn
                                        @click="getURLsByPortal"
                                        class="btn button-border-white"
                                        icon="mdi-magnify"
                                    ></v-btn>
                                </template>
                            </v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" md="3" class="text-start align-self-center">
                        <div style="display: inline-block; width: auto ">
                            <v-checkbox
                                label="Busqueda exacta"
                                v-model="url_search_data.exact_search"
                                :true-value="true"
                                :false-value="false"
                                color="blue"
                                hide-details
                            ></v-checkbox>
                        </div>
                    </v-col>
                    <v-col cols="12" md="9" class="text-end align-self-center">
                        <v-btn
                            class="btn button-primary mt-2 mt-sm-0 ms-sm-3"
                            @click="setQueueExcelElement"
                            :loading="create_redirection_loading"
                            :disabled="items.length === 0"
                        >Descargar Excel Blogs
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <Vue3EasyDataTable
                            table-class-name="primary-table courses-table-especific"
                            theme-color="#F4A649"
                            buttons-pagination
                            :headers="headers"
                            :items="items"
                            v-model:server-options="server_options"
                            :server-items-length="server_items_length"
                            :loading="loading"
                            rowsPerPageOptions="10"
                            rows-per-page-message="Elementos por página: "
                            rows-of-page-separator-message="de"
                            empty-message="No hay datos disponibles"
                            must-sort
                        >
                            <template #item-fecha_creacion="{fecha_creacion}">
                                {{ formatDate(fecha_creacion) }}
                            </template>

                            <template #item-published="{published}">
                                <span v-if="published === 1">SÍ</span>
                                <span v-else>NO</span>
                            </template>

                            <template #item-fecha_actualizacion="{fecha_actualizacion}">
                                <span v-if="variableIsDefined(fecha_actualizacion)"> {{
                                        formatDate(fecha_actualizacion)
                                    }}
                                </span>
                                <span v-else>-</span>
                            </template>

                            <template #item-curso_destacado="{curso_destacado, dominio_curso_destacado}">
                                <a class="text-decoration-none" target="_blank" :href="`https://www.${dominio_curso_destacado}/${curso_destacado}`">
                                    {{curso_destacado}}
                                </a>
                            </template>

                            <template #item-btn_actions="item">
                                <v-menu class="table-blog">
                                    <template v-slot:activator="{ props }">
                                        <img :src="require('@/assets/icons/more-options.svg')"
                                             class="btn button-bg-white icon-actions"
                                             alt="Icono puntos"
                                             v-bind="props">
                                    </template>
                                    <v-list>
                                        <!--Editar contenido SEO de url-->
                                        <router-link
                                            :to=editItem(item)
                                            target="_blank"
                                            class="link-unestyled"
                                        >
                                            <v-list-item>
                                                <v-list-item-title>
                                                    <span>Edición SEO</span>
                                                </v-list-item-title>
                                            </v-list-item>
                                        </router-link>

                                        <!--Crear url a partir de otra-->
                                        <v-list-item @click="showDialog(item.url, item.id_idioma, item.id_portal)">
                                            <v-list-item-title>
                                                <span>Url relacionadas</span>
                                            </v-list-item-title>
                                        </v-list-item>

                                        <!--Crear url a partir de otra-->
                                        <v-list-item @click="selectSEOURL(item.url)">
                                            <v-list-item-title>
                                                <span>Copiar a</span>
                                            </v-list-item-title>
                                        </v-list-item>

                                        <!--Activar/Desactivar url-->
                                        <v-list-item @click="displayDialogActive(item, item.activa)">
                                            <v-list-item-title>
                                                <span v-if="item.activa===1">Desactivar url</span>
                                                <span v-else>Activar url</span>
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </Vue3EasyDataTable>
                    </v-col>
                </v-row>

            </template>
            <template v-else>
                <v-row>
                    <v-col cols="12">
                        <span>Selecciona un idioma para continuar.</span>
                    </v-col>
                </v-row>
            </template>
            <v-dialog
                v-model="open_modal_url" persistent
            >
                <ModalUrl
                    :showDialog="showDialog"
                    :url_data="selected_url"
                    :portals="portals"
                    url_type="blogs"
                >
                </ModalUrl>
            </v-dialog>

            <v-dialog v-model="open_modal_seo_copy" persistent>
                <CopySEOModal
                    :displayDialog="displayDialogCopySEO"
                    :url="selected_url"
                    url_type="blogs"
                    :portals="portals"
                >
                </CopySEOModal>
            </v-dialog>
        </template>
    </v-container>
</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import {exportXLSX, formatDate, showNotification, variableIsDefined} from "@/utils/aux_functions";
import CopySEOModal from "@/components/CopySEO/CopySEOModal.vue";
import Loading from "@/components/Generic/Loading.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import FilterUrl from "@/components/UrlList/FilterUrl.vue";
import ModalUrl from "@/components/UrlList/ModalUrl.vue";
import TeachersModal from "@/components/Teachers/TeachersModal.vue";
import FormComment from "@/components/Comments/FormComment.vue";
import {useStore} from "vuex";
import {fireSweetAlert, initSweetAlert} from "@/utils/sweetalert";
import {changeStatusUrlWS} from "@/utils/courses";
import {getDeactivatedFeaturedFFAA, getDeactivatedFeaturedFFAAExcel} from "@/utils/deactivated_ffaa";

export default {
    name: "DeactivatedFeaturedFFAA",
    methods: {variableIsDefined, formatDate},
    components: {
        FormComment,
        TeachersModal,
        ModalUrl,
        FilterUrl,
        Vue3EasyDataTable,
        Loading,
        CopySEOModal
    },
    setup() {
        const store = useStore();
        const create_redirection_loading = ref(false);
        let general_loading = ref(false);

        let loading = ref(false);
        let open_modal_url = ref(false);
        let selected_url = ref({});
        let server_items_length = ref(0);
        let items = ref([]);
        let filter_search = ref({url_search: "", check_get_draft: 0, state_urls: 1});
        let language = computed(() => store.getters.getLanguageISO);
        let portal = computed(() => store.getters.getPortalID);
        let portals = ref([]);
        let pending_language = ref(false);
        let headers = ref([]);

        let open_modal_comment = ref(false);
        let open_modal_teacher = ref(false);
        let open_modal_seo_copy = ref(false);
        let open_modal_active = ref(false);

        let disabled_export = ref(true);

        let url_selected_active = {}

        let url_search_data = ref({});

        let server_options = ref({
            page: 1,
            rowsPerPage: 20,
            sortBy: 'url',
            sortType: 'asc',
        })


        onBeforeMount(async () => {
            general_loading.value = true
            initSweetAlert()
            setDatatableHeader();
            portals.value = computed(() => store.getters.getStoredPortals).value

            language.value !== 0 ? await getURLsByPortal() : pending_language.value = true
            general_loading.value = false
        });


        watch(server_options, async () => {
                await getURLsByPortal();
            },
            {deep: true}
        );

        watch(portal, async () => {
            items.value = [];
            await getURLsByPortal();
        });

        function setDatatableHeader() {
            headers.value = [
                {text: 'URL', value: 'url', sortable: true},
                {text: 'CURSO DESTACADO', value: 'curso_destacado', sortable: true},
                {text: 'FECHA CREACIÓN', value: 'fecha_creacion', sortable: true},
                {text: 'FECHA ACTUALIZACIÓN', value: 'fecha_actualizacion', sortable: true},
                {text: 'PUBLICADO', value: 'published'},
                {text: 'ACCIONES', value: 'btn_actions'},
            ]
        }


        async function getURLsByPortal() {
            loading.value = true;
            let order_by = '';
            let order_direction = '';

            if (variableIsDefined(server_options.value.sortBy) && variableIsDefined(server_options.value.sortType)) {
                order_by = server_options.value.sortBy;
                order_direction = server_options.value.sortType;
            }

            let response_all_urls = await getDeactivatedFeaturedFFAA(server_options.value, order_by, order_direction, portal.value, url_search_data.value.exact_search, url_search_data.value.url);

            if (response_all_urls['data'] && response_all_urls['data'].total && response_all_urls['result']) {
                items.value = response_all_urls['data'].data;
                server_items_length.value = response_all_urls['data'].total;
            } else {
                showNotification("error", response_all_urls['errors'].length ? response_all_urls['errors'] : "No hay datos disponibles")
                items.value = [];
                server_items_length.value = 0;
            }
            disabled_export.value = server_items_length.value === 0
            loading.value = false
        }


        async function setQueueExcelElement() {
            create_redirection_loading.value = true
            await getDeactivatedFeaturedFFAAExcel()
            create_redirection_loading.value = false
        }

        function displayDialogCopySEO() {
            open_modal_seo_copy.value = !open_modal_seo_copy.value;
        }

        function selectSEOURL(url) {
            selected_url.value = {
                'url': url,
                'url_type': 'blogs',
            };

            displayDialogCopySEO();
        }

        function showDialog(url, id_idioma, id_portal) {
            selected_url.value = {
                'url': url,
                'id_language': id_idioma,
                'id_portal': id_portal,
            }
            open_modal_url.value = !open_modal_url.value;
        }

        function editItem(item) {
            const portal = store.getters.getPortalID;
            const language = store.getters.getLanguageISO;
            store.commit('setTypeUrl', 'Blogs');
            localStorage.setItem('type_url', 'Blogs');

            return '/bridge/editor-seo?url=' + item.url + '&portal=' + portal + '&language=' + language;

        }

        function exportData() {
            exportXLSX("Cursos", 'blogs' + "-exportados.xlsx", items.value)
        }

        function displayDialogActive(item) {
            let state
            url_selected_active = item
            state = item.activa === 1 ? 'desactivar' : 'activar';
            fireSweetAlert(`¿Quieres ${state} el estado de la url?`, changeStateUrl)
        }

        async function changeStateUrl() {
            let state
            if (url_selected_active.activa === 1) {
                state = 0
            } else {
                state = 1
            }

            url_selected_active['tipo_url'] = "blog"

            let response = await changeStatusUrlWS(url_selected_active, state)
            if (response['result']) {
                showNotification('success', response['data']['message'])
                await getURLsByPortal()
            } else {
                showNotification('error', response['errors'])
            }
        }


        return {
            displayDialogActive,
            displayDialogCopySEO,
            editItem,
            exportData,
            getURLsByPortal,
            selectSEOURL,
            setQueueExcelElement,
            showDialog,

            create_redirection_loading,
            disabled_export,
            filter_search,
            general_loading,
            headers,
            items,
            loading,
            open_modal_active,
            open_modal_comment,
            open_modal_seo_copy,
            open_modal_teacher,
            open_modal_url,
            pending_language,
            portals,
            selected_url,
            server_items_length,
            server_options,
            url_search_data,
        }
    }
}
</script>

<style scoped lang="scss">

</style>