import {createRouter, createWebHistory} from 'vue-router';
import UploadSEMrush from "@/views/Semrush/UploadSEMrushView";
import ProposalKeywordView from "@/views/Keyword/ProposalKeywordView.vue";
import HomeView from "@/views/HomeView";
import EditorCourse from "@/views/Editor/EditorCourseView";
import EditorSeo from "@/views/Editor/EditorSeoView";
import TranslatorView from "@/views/Translator/TranslatorView.vue";
import TeachersManagerView from "@/views/Teachers/TeachersManagerView";
import PageNotFoundView from "@/views/PageNotFoundView.vue";
import ConceptualMapView from "@/views/ConceptualMap/ConceptualMapView.vue";
import SearchEngineView from "@/views/SearchEngine/SearchEngineView";
import ListConfigurationGadsView from "@/views/ConfigurationGADS/ListConfigurationGadsView.vue";
import AccessDenyView from "@/views/AccessDenyView.vue";
import ListCopywriterView from "@/views/CopyWriters/ListCopywriterView.vue";
import StatusManagementView from "@/views/Comments/StatusManagementView.vue";
import UrlListView from "@/views/UrlList/UrlListView.vue";
import ListComments from "@/views/Comments/ListCommentsView.vue";
import ManagerAdminView from "@/views/Admin/ManagerAdminView.vue";
import ManagerRolesView from "@/views/Admin/ManagerRolesView.vue";
import ManagerGroupView from "@/views/Admin/ManagerGroupView.vue";
import ManagerUserRolesView from "@/views/Admin/ManagerUserRolesView.vue";
import RankingCourse from "@/views/Ranking/RankingCourseView.vue";
import LogosCourseView from "@/views/DegreesCourse/DegreesCourseView.vue";
import ImagesLibraryView from "@/views/ImagesLibrary/ImagesLibraryView.vue";
import TeachersPendingView from "@/views/Teachers/TeachersPendingView.vue";
import AlertsView from "@/views/Alerts/AlertsView.vue";
import GenerateCourseAuto from "@/views/Course/GenerateCourseAuto.vue";
import CourseFaqView from "@/views/CourseFAQ/CourseFaqView.vue";
import NegativeWordsView from "@/views/NegativeWords/NegativeWordsView.vue";
import MediaValet from "@/views/MediaValet/MediaValetView.vue";
import ManagerGroupsRolView from "@/views/Admin/ManagerGroupsRolView.vue";
import CoursesVersionView from "@/views/Course/CoursesVersionView.vue";
import RedirectionsView from "@/views/Redirections/RedirectionsView.vue";
import TypeformListView from "@/views/TypeformList/TypeformListView.vue";
import TeachersEditView from "@/views/Teachers/TeachersEditView.vue";
import store from "@/store";
import ManagerGroupUsers from "@/views/Admin/ManagerGroupUsers.vue";
import ManagerGroupUserView from "@/views/Admin/ManagerGroupUserView.vue";
import PriorityCoursesView from "@/views/Course/PriorityCoursesView.vue";
import ExternalTeachersView from "@/views/Teachers/ExternalTeachersView.vue";
import CourseListLogoView from "@/views/UrlList/CourseListLogoView.vue";
import DocumentsView from "@/views/Documents/DocumentsView.vue";
import ManagerPortalsView from "@/views/Admin/ManagerPortalsView.vue";

import DeleteRedirectionsView from "@/views/Redirections/DeleteRedirectionsView.vue";
import QueueSearchReplaceView from "@/views/FindReplaceText/QueueSearchReplaceView.vue";
import RedirectionsQueueView from "@/views/Redirections/RedirectionsQueueView.vue";
import SEOCopyQueueView from "@/views/QueueSEOCopy/SEOCopyQueueView.vue";
import ManagerLanguagesView from "@/views/Admin/ManagerLanguagesView.vue";
import FindReplaceBulkTextView from "@/views/FindReplaceText/FindReplaceBulkTextView.vue";
import LogosQueueView from "@/views/QueueDegree/LogosQueueView.vue";
import DiplomasQueueView from "@/views/QueueDegree/DiplomasQueueView.vue";
import TrainingProgramView from "@/views/TrainingProgram/TrainingProgramView.vue";
import AssignDegreesView from "@/views/TrainingProgram/AssignDegreesView.vue";
import ManagerDegreesView from "@/views/ManagerDegrees/ManagerDegreesView.vue";
import QueueProposalKeywordView from "@/views/Keyword/QueueProposalKeywordView.vue";
import InformationRolesView from "@/views/Admin/InformationRolesView.vue";
import HomeOldAISEOView from "@/views/HomeOldAISEOView.vue";
import BlogsView from "@/views/UrlList/BlogsView.vue";
import {computed} from "vue";
import {variableIsDefined} from "@/utils/aux_functions";
import {logout} from "@/utils/auth";
import {fireMaintenanceAlert} from "@/utils/sweetalert";
import GenerateCourseFieldsView from "@/views/Course/GenerateCourseFieldsView.vue";
import QueueCourseFieldsView from "@/views/Course/QueueCourseFieldsView.vue";
import DeactivatedFeaturedFFAA from "@/views/UrlList/DeactivatedFeaturedFFAA.vue";

const routes = [
    {
        path: '/',
        name: 'inicio',
        redirect: '/bridge',
        component: HomeView,
        meta: {
            beauty_name: 'Inicio',
            image: require('@/assets/icons/sidebar/house-icon.svg')
        },
    },
    {
        name: 'bridge',
        path: '/bridge',
        component: HomeOldAISEOView,
        meta: {
            beauty_name: 'Bridge',
        },
    },
    {
        name: 'upload-semrush',
        path: '/bridge/upload-semrush',
        component: UploadSEMrush,
        meta: {
            beauty_name: 'Subir volcado SEMrush',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'listado-urls',
        path: '/bridge/listado-urls',
        component: UrlListView,
        meta: {
            beauty_name: 'Lista URLs',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: route.query.type,
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'aaff-destacadas-desactivadas',
        path: '/bridge/aaff-destacadas-desactivadas',
        component: DeactivatedFeaturedFFAA,
        meta: {
            beauty_name: 'Lista AAFF destacadas desactivadas',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Blogs',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'ranking-curso',
        path: '/bridge/ranking-curso',
        component: RankingCourse,
        meta: {
            beauty_name: 'Ranking curso',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: store.getters.getTypeUrl,
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: 'Lista URLs',
                        disabled: false,
                        to: 'listado-urls',
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'propuesta-keywords',
        path: '/bridge/propuesta-keywords',
        component: ProposalKeywordView,
        meta: {
            beauty_name: 'Propuestas Keywords',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    
    {
        name: "cola-propuestas",
        path: "/bridge/cola-propuestas-keywords",
        component: QueueProposalKeywordView,
        meta: {
            beauty_name: "Propuestas encoladas",
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Propuesta Keywords',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    
    {
        name: 'editor-curso',
        path: '/bridge/editor-curso',
        component: EditorCourse,
        meta: {
            beauty_name: 'Editor del curso',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Cursos',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: 'Lista URLs',
                        disabled: false,
                        to: 'listado-urls',
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'generacion-curso',
        path: '/bridge/generacion-curso',
        component: GenerateCourseAuto,
        meta: {
            beauty_name: 'Generación automática ficha-producto',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Cursos',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "cursos-prioritarios",
        path: "/bridge/cursos-prioritarios",
        component: PriorityCoursesView,
        meta: {
            beauty_name: 'Cursos prioritarios para docentes',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Cursos',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'editor-seo',
        path: '/bridge/editor-seo',
        component: EditorSeo,
        meta: {
            beauty_name: 'Editor seo',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: store.getters.getTypeUrl,
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: 'Lista URLs',
                        disabled: false,
                        to: 'listado-urls',
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'gestor-docentes',
        path: '/bridge/docentes',
        component: TeachersManagerView,
        meta: {
            beauty_name: 'Gestor de docentes',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Docentes',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'docente',
        path: '/bridge/docentes/:username',
        component: TeachersEditView,
        props: (route) => {
            const username = route.params.username
            const regex = /^[a-z]+\.{1}[a-z]+$/i;
            const result = ((regex.exec(username)) !== null)

            return result ? {username} : {username: ''};
        },
        meta: {
            beauty_name: 'Edición de docente',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Docentes',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: 'Gestor de docentes',
                        disabled: false,
                        to: 'gestor-docentes'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'docentes-listado',
        path: '/bridge/docentes-listado',
        component: TeachersPendingView,
        meta: {
            beauty_name: 'Docentes sin vincular',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Docentes',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'traductor',
        path: '/bridge/traductor',
        beauty_name: 'Traductor',
        component: TranslatorView,
        meta: {
            beauty_name: 'Traductor',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        },
    },
    {
        name: 'mapa-conceptual',
        path: '/bridge/mapa-conceptual',
        component: ConceptualMapView,
        meta: {
            beauty_name: 'Generador mapa conceptual',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'buscador',
        path: '/bridge/buscador/:engineType/:engineSuffix',
        props: (route) => {
            let engineSuffix = route.params.engineSuffix;
            const regex = /^[a-z]{2}-[a-z]{2}/;
            const result = ((regex.exec(engineSuffix)) !== null);

            let engineType = route.params.engineType;
            const engineTypeRegex = /(eduseo-cursos|contenidos-seo)/;
            const resultType = ((engineTypeRegex.exec(engineType)) !== null);

            engineSuffix = result ? engineSuffix : 'es-se';
            engineType = resultType ? engineType : 'eduseo-cursos';
            return {engineSuffix, engineType};
        },
        component: SearchEngineView,
        meta: {
            beauty_name: 'Buscador',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'configuracion-gads',
        path: '/bridge/listado-configuracion-google-adwords',
        component: ListConfigurationGadsView,
        meta: {
            beauty_name: 'Configuración GADS',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'redactores',
        path: '/bridge/redactores',
        component: ListCopywriterView,
        meta: {
            beauty_name: 'Listado de redactores',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'gestion-estados',
        path: '/bridge/gestion-estados',
        component: StatusManagementView,
        meta: {
            beauty_name: 'Gestión de estados para comentarios',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Comentarios',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'Listado comentarios',
        path: '/bridge/listado-comentarios',
        component: ListComments,
        meta: {
            beauty_name: 'Listado comentarios',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Comentarios',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'alerts',
        path: '/alertas',
        component: AlertsView,
        meta: {
            beauty_name: 'Panel de alertas',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }

        }
    },
    {
        name: 'documentacion',
        path: '/documentacion',
        component: DocumentsView,
        meta: {
            beauty_name: 'Manuales de referencia',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }

        }
    },
    {
        name: "admin",
        path: "/admin",
        component: ManagerAdminView,
        meta: {
            beauty_name: 'Panel de administración',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "roles",
        path: "/admin-roles",
        component: ManagerRolesView,
        meta: {
            beauty_name: 'Gestión de roles',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Panel de administración',
                        disabled: false,
                        to: 'admin'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "grupos",
        path: "/admin-grupos",
        component: ManagerGroupView,
        meta: {
            beauty_name: 'Gestión de roles por grupo',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Panel de administración',
                        disabled: false,
                        to: 'admin'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "roles-usuario",
        path: "/admin-roles-usuario",
        component: ManagerUserRolesView,
        meta: {
            beauty_name: 'Visualización de usuarios por rol',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Panel de administración',
                        disabled: false,
                        to: 'admin'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "grupos-roles",
        path: "/grupos-por-rol",
        component: ManagerGroupsRolView,
        meta: {
            beauty_name: 'Listado de grupos por roles',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Panel de administración',
                        disabled: false,
                        to: 'admin'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "gestion-portales",
        path: "/gestion-portales",
        component: ManagerPortalsView,
        props: () => {
            return {title: 'Gestión de portales'};
        },
        meta: {
            beauty_name: 'Gestión de portales',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Panel de administración',
                        disabled: false,
                        to: 'admin'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "gestion-idiomas",
        path: "/gestion-idiomas",
        component: ManagerLanguagesView,
        props: () => {
            return {title: 'Gestión de idiomas'};
        },
        meta: {
            beauty_name: 'Gestión de idiomas',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Panel de administración',
                        disabled: false,
                        to: 'admin'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "info-roles",
        path: "/informacion-roles",
        component: InformationRolesView,
        props: () => {
            return {title: 'Información sobre roles'};
        },
        meta: {
            beauty_name: 'Información sobre roles',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Panel de administración',
                        disabled: false,
                        to: 'admin'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "usuarios-grupo",
        path: "/usuarios-grupo",
        component: ManagerGroupUsers,
        meta: {
            beauty_name: 'Listado de usuarios por grupo',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Panel de administración',
                        disabled: false,
                        to: 'admin'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },

    {
        name: "grupos-usuario",
        path: "/grupos-usuario",
        component: ManagerGroupUserView,
        meta: {
            beauty_name: 'Grupos asignados a un usuario',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Panel de administración',
                        disabled: false,
                        to: 'admin'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },

    {
        name: 'images-library',
        path: '/bridge/imagenes',
        component: ImagesLibraryView,
        meta: {
            beauty_name: 'Biblioteca de imágenes',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "titulaciones-curso",
        path: "/bridge/titulaciones-curso",
        component: LogosCourseView,
        meta: {
            beauty_name: 'Titulaciones',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Cursos',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: 'Lista URLs',
                        disabled: false,
                        to: 'listado-urls',
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "gestion-versiones",
        path: "/bridge/gestion-versiones",
        component: CoursesVersionView,
        meta: {
            beauty_name: 'Gestión versiones cursos',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Cursos',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "faqs-curso",
        path: "/bridge/faqs-curso",
        component: CourseFaqView,
        meta: {
            beauty_name: 'Preguntas frecuentes',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Cursos',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: 'Lista URLs',
                        disabled: false,
                        to: 'listado-urls',
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "palabras-negativas",
        path: "/bridge/gestion-palabras-negativas",
        component: NegativeWordsView,
        meta: {
            beauty_name: 'Listado de palabras negativas',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "redirecciones-generales",
        path: "/bridge/redirecciones-generales",
        component: RedirectionsView,
        meta: {
            beauty_name: "Redirecciones generales",
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Redirecciones',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "cola-redirecciones",
        path: "/bridge/cola-redirecciones",
        component: RedirectionsQueueView,
        meta: {
            beauty_name: "Redirecciones encoladas",
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Redirecciones',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "eliminacion-masiva-redirecciones",
        path: "/bridge/eliminar-redirecciones",
        component: DeleteRedirectionsView,
        meta: {
            beauty_name: "Eliminación de redirecciones",
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Redirecciones',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "listado-typeforms",
        path: "/bridge/listado-typeform",
        component: TypeformListView,
        meta: {
            beauty_name: "Lista de Typeforms",
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    /*{
        path: '/blogs',
        name: 'blogs',
        component: BlogsView,
        meta: {
            beauty_name: 'Blogs',
            image: require('@/assets/icons/sidebar/icon-blog.svg'),
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        },
    },*/
    {
        name: "cola-copias",
        path: "/bridge/cola-copias",
        component: SEOCopyQueueView,
        meta: {
            beauty_name: "Blogs encolados para copia SEO",
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Blogs',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    /*{
        name: "cola-reemplazos",
        path: "/bridge/cola-reemplazos",
        component: QueueSearchReplaceView,
        meta: {
            beauty_name: "Reemplazos encolados",
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Búsqueda y reemplazo',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },*/
    /*{
        name: "busqueda-reemplazo-masivo",
        path: "/bridge/busqueda-reemplazo-masivo",
        component: FindReplaceBulkTextView,
        meta: {
            beauty_name: 'Búsqueda y reemplazo de texto de manera masiva',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Búsqueda y reemplazo',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },*/
    {
        name: "mediavalet",
        path: "/bridge/mediaValet",
        component: MediaValet,
        meta: {
            beauty_name: 'Media Valet',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "info-portales",
        path: "/bridge/info-portales",
        component: ManagerPortalsView,
        props: () => {
            return {title: 'Información de portales', can_edit_portals: false, get_languages: true};
        },
        meta: {
            beauty_name: 'Información de portales',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'docentes-externos',
        path: '/bridge/docentes-externos',
        component: ExternalTeachersView,
        meta: {
            beauty_name: 'Listado docentes externos',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Docentes',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'cola-logos',
        path: '/bridge/cola-logos',
        component: LogosQueueView,
        meta: {
            beauty_name: 'Gestión logos encolados',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Logos y diplomas',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'programas-formativos',
        path: '/bridge/programas-formativos',
        component: TrainingProgramView,
        meta: {
            beauty_name: 'Listado programas formativos',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "asignacion-logo-diploma-programa-formativo",
        path: "/bridge/asignacion-logo-diploma-programa-formativo",
        component: AssignDegreesView,
        meta: {
            beauty_name: 'Asignación de logos y diplomas',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Listado programas formativos',
                        disabled: false,
                        to: 'programas-formativos',
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'gestion-logos-diplomas',
        path: '/bridge/gestion-logos-diplomas',
        component: ManagerDegreesView,
        meta: {
            beauty_name: 'Gestión de logos y diplomas',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Logos y diplomas',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: 'cola-diplomas',
        path: '/bridge/cola-diplomas',
        component: DiplomasQueueView,
        meta: {
            beauty_name: 'Gestión diplomas encolados',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Logos y diplomas',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "AsignacionLogoDiploma",
        path: "/bridge/asignacion-logos-diplomas",
        component: CourseListLogoView,
        meta: {
            beauty_name: 'Asignación de logos y diplomas a cursos',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'bridge'
                    },
                    {
                        title: 'Logos y diplomas',
                        disabled: false,
                        to: 'bridge',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "GeneracionCamposFichaProd",
        path: "/generacion-campos-ficha-producto",
        component: GenerateCourseFieldsView,
        meta: {
            beauty_name: 'Generación de campos para ficha de producto',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: 'Cursos',
                        disabled: false,
                        to: 'inicio',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    {
        name: "cola-campos-ficha-prod",
        path: "/cola-campos-ficha-producto",
        component: QueueCourseFieldsView,
        meta: {
            beauty_name: 'Documentos de campos ficha producto encolados',
            breadCrumb(route) {
                return [
                    {
                        title: 'Inicio',
                        disabled: false,
                        to: 'inicio'
                    },
                    {
                        title: 'Cursos',
                        disabled: false,
                        to: 'inicio',
                        children: true
                    },
                    {
                        title: route.meta.beauty_name,
                        disabled: true,
                    },
                ]
            }
        }
    },
    /* PÁGINA 404 */
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: PageNotFoundView,
        meta: {
            beauty_name: 'Página no encontrada',
        }
    },
    {
        path: "/acceso-denegado",
        name: "not-allowed",
        component: AccessDenyView,
        meta: {
            beauty_name: 'Acceso denegado',
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach((to, from) => {
    if (process.env.VUE_APP_MAINTENANCE === "1" && variableIsDefined(store.getters.getRolesUser) && !store.getters.getRolesUser.some(rol => rol === 7)){
         logout();
    }
})

export default router
