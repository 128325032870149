import {getAicoreToken, showNotification, variableIsDefined} from "@/utils/aux_functions";

export async function getDeactivatedFeaturedFFAA({page, rowsPerPage}, order_by, order_direction, portal, exact_search = false, url_to_search = "") {
    let response = await getAicoreToken({analytics: true})
    let token = response['access_token']
    let ws_url = process.env.VUE_APP_EDUSEO_ANALYTICS_URL + `reportes/get_aa_ff_destacadas_desactivadas?id_portal=${portal}&page=${page}&page_size=${rowsPerPage}&column_order=${order_by}&type_order=${order_direction}`

    if (variableIsDefined(exact_search) && variableIsDefined(url_to_search)){
        ws_url += `&url=${url_to_search}&busqueda_exacta=${exact_search}`
    }

    try {
        return $.ajax({
            url: ws_url,
            type: 'GET',
            data: {},
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'Buscar_y_Reemplazar/find_and_delete_links_with_excel');
    }
}

export async function getDeactivatedFeaturedFFAAExcel() {
    let response = await getAicoreToken({analytics: true})
    let token = response['access_token']

    try {
        return $.ajax({
            url: process.env.VUE_APP_EDUSEO_ANALYTICS_URL + `reportes/get_excel_aa_ff_destacadas_desactivadas`,
            type: 'GET',
            xhrFields: {
                responseType: 'blob'
            },
            data: {},
            headers: {
                'Authorization': 'Bearer ' + token
            },
            success: function (blob) {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'aaff_destacadas_desactivadas.xlsx';
                link.click();
                window.URL.revokeObjectURL(url);
            },
        })
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'Buscar_y_Reemplazar/find_and_delete_links_with_excel');
    }
}