import md5 from "md5";
import store from "@/store";
import {getSweetNotification} from "@/utils/sweetalert";


export const activateRipple = (element) => {
    let ev = new Event("mousedown")
    let offset = element.getBoundingClientRect()
    ev.clientX = offset.left + offset.width/2
    ev.clientY = offset.top + offset.height/2
    element.dispatchEvent(ev)

    setTimeout(function() {
        element.dispatchEvent(new Event("mouseup"))
    }, 300)
}

export const generateRandomString = (num) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result1 = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < num; i++) {
        result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result1;
}

export function htmlDecode(input) {
    let ret = "";
    if (typeof input !== 'undefined' && input !== null) {
        ret = input.replace(/&gt;/g, '>');
        ret = ret.replace(/&lt;/g, '<');
        ret = ret.replace(/&quot;/g, '"');
        ret = ret.replace(/&apos;/g, "'");
    }
    return ret;
}

export function getHtmlElements(text, tag) {
    const parser = new DOMParser()
    const content = parser.parseFromString(text, 'text/html')
    const html_elements = content.getElementsByTagName(tag);

    return html_elements
}

export function showNotification(tipo, text, many_messages = false, msg = '') {
    let notification_text = text;

    if (typeof text === "object") {
        if (many_messages) {
            notification_text = '';
            text.forEach(item => {
                notification_text += item + '\n';
            });
        } else {
            if (text[0].title !== undefined) {
                notification_text = text[0].title;
            }
            if (text[0].message !== undefined) {
                notification_text = text[0].message;
            }
        }
    }
    /*NECESITAS AGRUPAR VARIAS RESPUESTAS EN UNA NOTIFICACIÓN*/
    if (many_messages) {
        let list_items = '';
        if (variableIsDefined(notification_text)) {
            /*MUESTRA UN MENSAJE PREDETERMINADO ANTES DE LA NOTIFICACIÓN DEL RESPONSE*/
            if (variableIsDefined(msg)) {
                list_items = msg
            }

            /*RECORRE EL RESPONSE DATA, PARA MOSTRARLOS TODOS EN LA MISMA NOTIFICACIÓN*/
            notification_text.split('\n').forEach(item => {
                list_items += `<li>${item}</li>`;
            });
        }
        /*MUESTRA LA NOTIFICACIÓN*/
        const list_content = `<ul>${list_items}</ul>`;
        getSweetNotification().fire({
            icon: tipo,
            text: notification_text,
            html: list_content
        })

        /*SOLO TIENE UNA RESPUESTA*/
    } else {
        getSweetNotification().fire({
            icon: tipo,
            text: notification_text,
        })
    }
}

export function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
}


export function createTokenAISEO(params = {}) {

    var final_token = "";

    Object.entries(params).sort().forEach(([key, value]) => {
        final_token += key + '=' + value + ';'
    });

    return md5(process.env.VUE_APP_AISEO_TOKEN_WS_KEY + final_token);
}

export async function getAicoreToken({ext = false, analytics =false}) {
    let env_password = process.env.VUE_APP_AICORE_AUTH_PASSWORD;
    let env_user = process.env.VUE_APP_AICORE_AUTH_USER;
    let env_token = process.env.VUE_APP_AICORE_URL_TOKEN;

    if (analytics){
        env_password = process.env.VUE_APP_AICORE_AUTH_EXT_PASSWORD;
        env_user = process.env.VUE_APP_AICORE_AUTH_EXT_USER;
        env_token = process.env.VUE_APP_EDUSEO_ANALYTICS_TOKEN_URL;
    }

    if (ext) {
        env_password = process.env.VUE_APP_AICORE_AUTH_EXT_PASSWORD;
        env_user = process.env.VUE_APP_AICORE_AUTH_EXT_USER;
        //env_token = process.env.VUE_APP_AICORE_URL_TOKEN_LOOKER
        env_token = process.env.VUE_APP_AICORE_URL_TOKEN_EXT
    }

    let password = Math.random().toString(36).substring(2, 6) + env_password + Math.random().toString(36).substring(2, 6)

    return $.ajax({
        type: "POST",
        url: env_token,
        data: {
            username: env_user,
            password: btoa(password)
        }
    });
}

export function createTokenESCIC(params = {}, api = false) {

    let final_token = "";

    Object.entries(params).sort().forEach(([key, value]) => {
        final_token += key + '=' + value + ';'
    });
    
    return md5((api ? process.env.VUE_APP_ESCIC_API_TOKEN_WS_KEY:process.env.VUE_APP_ESCIC_TOKEN_WS_KEY) + final_token);
}

export function exportXLSX(name_sheet, file_name, data) {
    let XLSX;
    if (typeof XLSX == 'undefined') XLSX = require('xlsx');
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, name_sheet);
    XLSX.writeFile(wb, file_name);
}


export async function copyClipboard(data) {
    navigator.clipboard.writeText(data).then(
        () => {
            showNotification("success", "Dato copiado")
        },
        () => {
            showNotification("error", "No se ha podido copiar")
        },
    );

}

export function variableIsDefined(variable) {
    return (variable !== undefined && variable !== null && variable !== "" && variable !== 0 && !isEmptyArray(variable) && variable !== {})
}

export function isEmptyArray(variable) {
    return Object.prototype.toString.call(variable) === '[object Array]' && variable.length === 0;
}

export function removeAccents(string) {
    return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}


export function b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded Unicode,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
}

/*COMPARAR DOS OBJETOS*/
export function objectsHasSameInfo(objetoA, objetoB, strict = false) {
    // Comprobar si ambos parámetros son objetos
    if (typeof objetoA !== 'object' || typeof objetoB !== 'object') {
        return false;
    }

    // Obtener las claves de las propiedades de cada objeto
    const clavesObjetoA = Object.keys(objetoA);
    const clavesObjetoB = Object.keys(objetoB);

    // Comprobar si el número de propiedades es el mismo
    if (clavesObjetoA.length !== clavesObjetoB.length) {
        return false;
    }

    // Recorrer las claves y comparar los valores de las propiedades
    for (const clave of clavesObjetoA) {
        if (!clavesObjetoB.includes(clave)) {
            return false;
        } else {
            if (strict) {
                if (objetoA[clave] !== objetoB[clave]) {
                    return false;
                }
            } else {
                if (objetoA[clave] != objetoB[clave]) {
                    return false;
                }
            }

        }
    }

    // Si todas las comparaciones pasaron, los objetos son iguales
    return true;
}


export function deleteTagsHTML(text) {
    // Utiliza una expresión regular para eliminar todas las etiquetas HTML
    return text.replace(/<[^>]*>/g, '');
}

/*FORMATEAR FECHA, OBTENIENDO FECHA Y HORA*/
export function formatDate(complete_date) {

    if (variableIsDefined(complete_date)){
        let date = new Date(complete_date.toString().substring(0, 10)),
            day = '' + date.getDate(),
            month = '' + (date.getMonth() + 1),
            year = '' + date.getFullYear();

        if (day.length < 2) {
            day = '0' + day;
        }

        if (month.length < 2)
            month = '0' + month;

        let date_first = [day, month, year].join('-');
        let date_second = complete_date.toString().substring(11);

        return date_first + ' ' + date_second;
    }else {
        return ''
    }

}

export function validateEmail(email) {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return ((regex.exec(email)) !== null);
}


export function isURLAbsolute(url) {
    const regex = /(?:https):\/\/[-a-z0-9+&@#\/%?=~_|!:,.;]*[-a-z0-9+&@#\/%=~_|]$/;
    return regex.test(url);
}

/*COMPROBAR SI UN OBJETO CONTIENE DATOS*/
export function checkDefinedObject(object) {
    return Object.keys(object).length !== 0
}


export async function manageWSResponseMultiple(response) {
    let categorization_response = []
    if (response['result']) {
        if (variableIsDefined(response['data']['success'])) {
            response['data']['success'].forEach((item) => {
                if (Array.isArray(item)) {
                    item.forEach((other_success) => {
                        categorization_response.push({'success': other_success.title})
                    });
                } else {
                    categorization_response.push({'success': item.title})
                }
            });
        }
        if (variableIsDefined(response['data']['warnings'])) {
            response['data']['warnings'].forEach((item) => {
                if (Array.isArray(item)) {
                    item.forEach((other_warning) => {
                        categorization_response.push({'warning': other_warning.title})
                    })
                } else {
                    categorization_response.push({'warning': item.title})
                }
            })
        }
        if (variableIsDefined(response['data']['errors'])) {
            response['data']['errors'].forEach((item) => {
                if (Array.isArray(item)) {
                    item.forEach((other_error) => {
                        categorization_response.push({'error': other_error.title})
                    })
                } else {
                    categorization_response.push({'error': item.title})
                }
            })
        }
    } else {
        if (variableIsDefined(response['errors']['warnings'])) {
            response['errors']['warnings'].forEach((warning) => {
                warning.forEach((other_warning) => {
                    categorization_response.push({'warning': other_warning.title})
                })
            })
        }
        if (variableIsDefined(response['errors']['errors'])) {
            response['errors']['errors'].forEach((error) => {
                error.forEach((other_error) => {
                    categorization_response.push({'error': other_error.title})
                })
            })
        }
    }
    return categorization_response
}



/*FORMATEAR FECHA (OBTIENES FECHA SIN HORA);
date_long=Fecha obtenida
composite_date= si la fecha esta ya formateada, debe ir a false
order= tipo de ordenación */
export function formatOnlyDate(date_long, composite_date = false, order = 'dd-mm-aaaa') {
    let date;
    if (variableIsDefined(date_long)) {
        if (composite_date) {
            date = new Date(date_long);
            if (order === 'dd-mm-aaaa') {
                let options = {day: '2-digit', month: '2-digit', year: 'numeric'};
                return date.toLocaleDateString('es-ES', options).replace(/\//g, '-');
            } else if (order === 'aaaa-mm-dd') {
                let year = date.getFullYear();
                let month = ('0' + (date.getMonth() + 1)).slice(-2);
                let day = ('0' + date.getDate()).slice(-2);
                return `${year}-${month}-${day}`;
            } else {
                return 'Formato no válido'
            }
        } else {
            date = date_long.split(/[-\/T]/)
            if (order === 'dd-mm-aaaa') {
                return `${date[0]}-${date[1]}-${date[2]}`;

            } else if (order === 'aaaa-mm-dd') {
                return `${date[2]}-${date[1]}-${date[0]}`
            }
        }
    } else {
        return null
    }
}
