<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" sm="9" md="9" lg="10">
                <span class="main-title-page">Manuales de referencia</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <router-link class="link" to="/bridge/info-portales" target="_blank">
                    <img :src="require('@/assets/icons/pdf.svg')" alt="Icono pdf" style="width: 28px">
                    Guía de información básica de portales
                </router-link>
            </v-col>
            <template v-for="document in document_list">
                <v-col cols="6">
                    <router-link class="link" :to="'/docs/' + document.href" target="_blank">
                        <img :src="require('@/assets/icons/pdf.svg')" alt="Icono pdf" style="width: 28px">
                        {{ document.name }}
                    </router-link>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>

import {onBeforeMount, ref} from "vue";
import {hasPermission} from "@/utils/auth";
import {useRoute} from "vue-router";

export default {
    name: "DocumentsView",
    setup() {
        const route = useRoute();

        onBeforeMount(async () => {
            await hasPermission(route)
        })

        const document_list = ref([
            {"name": "Biblioteca de imágenes", "href": "biblioteca_de_imagenes.pdf"},
            {"name": "Cola de redirecciones", "href": "cola_redirecciones.pdf"},
            {"name": "Copiar contenido SEO", "href": "copiar_contenido_SEO.pdf"},
            {"name": "Editor SEO", "href": "editor_SEO.pdf"},
            {"name": "Editor de cursos", "href": "editor_curso.pdf"},
            {"name": "Eliminación de redirecciones", "href": "eliminacion_de_redirecciones.pdf"},
            {"name": "Fichero importación redirecciones", "href": "fichero_redirecciones.pdf"},
            {"name": "Generación de fichas de producto", "href": "generacion_fichas_producto.pdf"},
            {"name": "Gestión de estados para comentarios", "href": "gestion_estados_comentarios.pdf"},
            {"name": "Gestión de logos y diplomas", "href": "gestion_logos_diplomas.pdf"},
            {"name": "Gestión de titulaciones para un curso", "href": "gestion_titulaciones_curso.pdf"},
            {"name": "Gestión de versiones para un curso", "href": "gestion_versiones_cursos.pdf"},
            {"name": "Información sobre portales", "href": "info_portales.pdf"},
            {"name": "Listado comentarios", "href": "listado_comentarios.pdf"},
            {"name": "Listado de docentes", "href": "listado_docentes.pdf"},
            {"name": "Listado programas formativos", "href": "listado_programas_formativos.pdf"},
            {"name": "Listado de typeform", "href": "listado_typeform.pdf"},
            {"name": "Listado de urls", "href": "listado_url.pdf"},
            {"name": "Asignación masiva de logos y diplomas", "href": "logo_diploma_masivos.pdf"},
            {"name": "Gestión logos y diplomas encolados", "href": "logos_diplomas_encolados.pdf"},
            {"name": "Palabras negativas", "href": "palabras_negativas.pdf"},
            {"name": "Pantalla de documentación", "href": "pantalla_documentacion.pdf"},
            {"name": "Previsualización titulaciones del curso", "href": "previsualizacion_titulaciones_del_curso.pdf"},
            {"name": "Redactores", "href": "redactores.pdf"},
            {"name": "Redirecciones", "href": "redirecciones.pdf"},
            {"name": "Usuarios externos como docentes", "href": "usuarios_externos_docentes.pdf"}
        ])

        return {
            document_list
        }
    }
}
</script>

<style lang="scss">
.link {
    font-size: 18px;
    color: $base-text-color;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        color: $blue-darker;
        font-weight: 600;
    }
}
</style>